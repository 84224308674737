import { createAppConfig } from '@newcross-tech/app-config-core'
import * as y from 'yup'

export const AppConfig = createAppConfig({
  parse: (values) =>
    y
      .object({
        env: y.string().required(),
        apiUrl: y.string().required(),
        errorPageDisplayDevInfo: y.boolean().optional(),
        sentry: y
          .object({
            enabled: y.boolean().optional(),
            dsn: y.string().optional(),
            tracesSampleRate: y.number().optional(),
            replaysSessionSampleRate: y.number().optional(),
            replaysOnErrorSampleRate: y.number().optional(),
          })
          .required(),
        myAccount: y
          .object({
            hideWithdrawSection: y.boolean().optional(),
          })
          .required(),
        pendo: y
          .object({
            enabled: y.boolean().optional(),
            apiKey: y.string().optional(),
          })
          .required(),
        store: y
          .object({
            exposeGlobal: y.boolean().optional(),
            globalName: y.string().optional(),
            devToolsEnabled: y.boolean().optional(),
          })
          .optional(),
        googleAnalytics: y
          .object({
            enabled: y.boolean().optional(),
            trackingId: y.string().required(),
            hostname: y.string().required(),
          })
          .optional(),
        links: y
          .object({
            oafLogin: y.string().required(),
          })
          .required(),
        accessNi: y
          .object({
            employerPin: y.string().required(),
            niDirectPortalURL: y.string().required(),
          })
          .required(),
        intercomEnabled: y.boolean().optional(),
        devAssertEnabled: y.boolean().optional(),
      })
      .required()
      .validateSync(values, { strict: true, recursive: true }),
})
